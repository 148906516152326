import React, { Component } from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { connect } from 'react-redux'
import Title from '../components/page-title'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'

import { setLightboxForm } from '../actions'
import PageCtaFeature from '../components/page-cta-feature'

import Image1 from '../images/etc/happy-haus-holland-park-west-14.jpg'
import Image2 from '../images/etc/happy_haus_darlington_047.jpg'

const InclusionImage1 = 'etc/happy_haus_darlington_006.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
]

const mapDispatchToProps = dispatch => ({})

class Page extends Component {
  constructor(props) {
    super(props)
    this._handleRequestClick = this._handleRequestClick.bind(this)
  }

  _handleRequestClick(e, form) {
    e.preventDefault()
    const { dispatch } = this.props

    switch (form) {
      case 'builder-checklist':
        dispatch(
          setLightboxForm({
            category: 'Builder Checklist',
            title: 'Get your Builder Checklist',
            redirect: '/builder-checklist-thank-you',
          })
        )
        break
    }
  }

  render() {
    const CtaFeatures = [
      {
        title: '&nbsp;',
        image: InclusionImage1,
        buttonLabel: 'Download our checklist',
        bgColor: '#f1f1f1',
        bgColorHover: '#eeeeee',
        onClick: e => {
          this._handleRequestClick(e, 'builder-checklist')
        },
      },
    ]
    return (
      <>
        <SEO
          title="Builder Checklist"
          description="We understand finding the right builder can be tricky.
                  Simplify the path to purchasing your new home by knowing
                  exactly what to look for when comparing inclusions and
                  optional upgrades."
          images={ogImages}
        />{' '}
        <Row>
          <Flex flexWrap="wrap" flexDirection={['column-reverse', 'row']}>
            <Box width={[1, 6 / 12]} px={2}>
              <Title>Looking for the right builder?</Title>
            </Box>
            <Box width={[1, 6 / 12]} px={2}>
              <Copy align="left">
                <p>
                  We understand finding the right builder can be tricky.
                  Simplify the path to purchasing your new home by knowing
                  exactly what to look for when comparing inclusions and
                  optional upgrades.
                </p>
                <p>
                  Happy Haus makes architecture you can own—considered homes
                  that balance design, cost and quality. Learn about our
                  standard Happy Haus inclusions, some of which other builders
                  refer to as upgrades.
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <PageCtaFeature features={CtaFeatures} />
      </>
    )
  }
}

export default connect(mapDispatchToProps)(Page)
