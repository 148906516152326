import React, { Component } from 'react'
import styled from 'styled-components'
import { theme, fontBold } from '../util/style'
import { Flex, Box } from '@rebass/grid'
//import CtaBox from './page-cta-box';
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'

class PageCtaFeature extends Component {
  render() {
    const { features, align } = this.props

    const boxes = features.map((feature, i) => {
      const { style, content, plus } = feature
      const key = `feature-${i}`

      if (style === 'cols') {
        return (
          <FeatureCols
            key={key}
            bgDefault={feature.bgColor}
            bgHover={feature.bgColorHover}
            onClick={feature.onClick}
            align={align ? align : 'center'}
          >
            <ImageWrap>
              <Image src={feature.image} alt="" />
            </ImageWrap>
            <Content fgColor={feature.fgColor}>
              {content}
              <Button bgColor={feature.fgColor}>{feature.buttonLabel}</Button>
              <br />
              <br />
              <Plus>
                <strong>{plus}</strong>
              </Plus>
            </Content>
          </FeatureCols>
        )
      } else {
        return (
          <Feature
            key={key}
            bgDefault={feature.bgColor}
            bgHover={feature.bgColorHover}
            onClick={feature.onClick}
            align={align ? align : 'center'}
          >
            <Title dangerouslySetInnerHTML={{ __html: feature.title }} />
            <SubTitle dangerouslySetInnerHTML={{ __html: feature.subTitle }} />
            <ImageWrap>
              <Image src={feature.image} alt="" />
            </ImageWrap>
            <Button>{feature.buttonLabel}</Button>
          </Feature>
        )
      }
    })

    const padding = align ? 0 : 2
    return (
      <Row>
        <Flex flexWrap="wrap">
          <Box width={[1, 12 / 12]} px={padding} pr={[0, 0]}>
            <Features>{boxes}</Features>
          </Box>
        </Flex>
      </Row>
    )
  }
}

export default PageCtaFeature

const Features = styled.div`
  display: flex;
  align-items: flex-start;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`

const Title = styled.span`
  display: block;
  ${fontBold()};
  color: ${theme.colors.grey1};
  font-size: 148%;
  line-height: 126%;
  margin-bottom: 0.5em;
  @media (max-width: 1200px) {
    display: none;
  }
`

const SubTitle = styled.span`
  display: block;
  color: ${theme.colors.grey1};
  padding: 0 1em;
  line-height: 150%;
  @media (max-width: 1200px) {
    display: none;
  }
`

const ImageWrap = styled.span`
  display: block;
  margin: 1.7em auto;
  width: 45%;
  position: relative;
  top: 0;
  transition: top 0.1s ease-out;
  img {
    box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 800px) {
    width: 95%;
    margin-top: 0px;
  }
`

const Button = styled.span`
  display: inline-block;
  padding: 20px;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0;
  color: ${theme.colors.white};
  background: ${({ bgColor }) => (bgColor ? bgColor : theme.colors.grey1)};
  @media (max-width: 800px) {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const Plus = styled.span`
  color: #ffffff;
  font-size: 95%;
`
const Content = styled.span`
  display: block;
  text-align: left;
  padding: 1.7em 32px 1.7em 6px;
  color: ${({ fgColor }) => (fgColor ? fgColor : theme.colors.grey1)};
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
    padding: 1.7em 24px 1.7em 24px;
  }
`

const Feature = styled.button`
  background: ${(props) => props.bgDefault};
  width: 100%;
  padding: ${(props) => (props.align !== 'center' ? '2em 0em' : '2em 1em')};
  text-align: ${(props) => props.align};
  cursor: pointer;
  outline: none;
  border: none;
  &:hover {
    background: ${(props) => props.bgHover};
    ${ImageWrap} {
      top: -4px;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 2em 1em;
  }
`

const FeatureCols = styled.button`
  background: ${(props) => props.bgDefault};
  width: 100%;
  padding: 2em 0em 5em 0em;
  text-align: ${(props) => props.align};
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  ${ImageWrap} {
    width: 50%;
    padding-left: 6%;
    padding-right: 6%;
  }
  ${Button} {
    margin-top: 1.8em;
  }
  &:hover {
    background: ${(props) => props.bgHover};
    ${ImageWrap} {
      top: -4px;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    padding: 2em 0em;
    display: block;
    ${ImageWrap} {
      width: 80%;
      padding-left: 10%;
      padding-right: 10%;
    }
  }
`
